import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { render } from 'react-dom';
import { SwitchTransition, CSSTransition } from 'react-transition-group';

function ImageBox({ imageUrl, url, label, isLight }) {
  return (
    <div
      className="home__stylists-section__featured-image"
      style={{ backgroundImage: `url(${imageUrl})` }}
    >
      <span className="home__stylists-section__featured-image__label px-3 pb-2">
        {isLight ? (
          <a className="text-white" target="_blank" rel="noreferrer" href={url}>
            @{label}
          </a>
        ) : (
          <a target="_blank" rel="noreferrer" href={url}>
            @{label}
          </a>
        )}
      </span>
    </div>
  );
}

ImageBox.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isLight: PropTypes.bool.isRequired,
};

function ItemBox({ brand, listPrice, price, imageUrl }) {
  return (
    <div className="home__stylists-section__item flex-column justify-content-between px-3 pb-2">
      <div className="text-center">
        <img src={imageUrl} className="img-fluid home__stylists-section__item__img" alt="Tori" />
      </div>
      <div className="home__stylists-section__item__meta text-center mt-auto">
        <div className="font-weight-bold">{brand}</div>
        <div className="home__stylists-section__item__prices">
          <span className="font-weight-bold">{price}</span>{' '}
          <span className="text-line-through">{listPrice}</span>
        </div>
      </div>
    </div>
  );
}

ItemBox.propTypes = {
  brand: PropTypes.string.isRequired,
  listPrice: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
};

function Stylists({ stylists }) {
  const ref = useRef(null);
  const [activeStylistIndex, setActiveStylistIndex] = useState(0);
  const [didUserSelected, setDidUserSelect] = useState(false);
  const activeStylist = stylists[activeStylistIndex];
  const { featuredImages, items } = activeStylist;
  const numOfStylists = stylists.length;
  const [id, setId] = useState(null);

  function onStylistClick(index) {
    setDidUserSelect(true);
    setActiveStylistIndex(index);
  }

  const nextStylist = useCallback(() => {
    setActiveStylistIndex((activeStylistIndex + 1) % numOfStylists);
  }, [activeStylistIndex, numOfStylists]);

  useEffect(() => {
    let intId;
    if (!didUserSelected) {
      intId = setInterval(nextStylist, 4000);
      setId(intId);
    } else {
      setId(null);
    }

    return () => clearInterval(intId);
  }, [nextStylist, didUserSelected]);

  const clear = useCallback(() => {
    clearInterval(id);
  }, [id]);

  useEffect(() => {
    const target = ref.current;
    target.addEventListener('mouseenter', clear);
    return () => {
      target.removeEventListener('mouseenter', clear);
    };
  }, [clear]);

  return (
    <div ref={ref}>
      <div className="d-flex align-items-center justify-content-center mb-3">
        {stylists.map((stylist, index) => (
          <button
            type="button"
            key={stylist.id}
            onClick={() => onStylistClick(index)}
            className={cx('btn home__stylists-section__stylist-button  rounded-circle', {
              'home__stylists-section__stylist-button--active': activeStylistIndex === index,
            })}
          >
            <img src={stylist.profilePicUrl} className="img-fluid rounded-circle" alt="Tori" />
            <div className="mt-1">{stylist.name}</div>
          </button>
        ))}
      </div>
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={activeStylistIndex}
          addEndListener={(node, done) => {
            node.addEventListener('transitionend', done, false);
          }}
          classNames="home__stylists-section__fade"
        >
          <div style={{ minHeight: '604px' }} className="px-1">
            <div className="home__stylists-section__featured-images-wrapper">
              {featuredImages.map((featuredImage) => (
                <ImageBox
                  key={featuredImage.url}
                  imageUrl={featuredImage.url}
                  url={featuredImage.linkUrl}
                  label={featuredImage.handle}
                  isLight
                />
              ))}
            </div>
            <div className="home__stylists-section__items-wrapper p-3 px-md-0">
              <ImageBox
                imageUrl={featuredImages[0].url}
                url={featuredImages[0].linkUrl}
                label={featuredImages[0].handle}
                isLight
              />
              <ItemBox
                key={items[0].brand}
                brand={items[0].brand}
                price={items[0].price}
                listPrice={items[0].listPrice}
                imageUrl={items[0].imageUrl}
              />

              <ItemBox
                key={items[1].brand}
                brand={items[1].brand}
                price={items[1].price}
                listPrice={items[1].listPrice}
                imageUrl={items[1].imageUrl}
              />
              <ImageBox
                imageUrl={featuredImages[1].url}
                url={featuredImages[1].linkUrl}
                label={featuredImages[1].handle}
                isLight
              />

              <ImageBox
                imageUrl={featuredImages[2].url}
                url={featuredImages[2].linkUrl}
                label={featuredImages[2].handle}
                isLight
              />
              <ItemBox
                key={items[2].brand}
                brand={items[2].brand}
                price={items[2].price}
                listPrice={items[2].listPrice}
                imageUrl={items[2].imageUrl}
              />

              {items.slice(-4).map((item) => (
                <ItemBox
                  key={item.brand + item.price}
                  brand={item.brand}
                  price={item.price}
                  listPrice={item.listPrice}
                  imageUrl={item.imageUrl}
                />
              ))}
            </div>
          </div>
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
}

Stylists.propTypes = {
  stylists: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      profilePicUrl: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      featuredImages: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string.isRequired,
          handle: PropTypes.string.isRequired,
          linkUrl: PropTypes.string.isRequired,
        }),
      ).isRequired,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          brand: PropTypes.string.isRequired,
          price: PropTypes.string.isRequired,
          listPrice: PropTypes.string.isRequired,
          imageUrl: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
  ).isRequired,
};

render(
  <Stylists stylists={window.MATERIAL_WORLD.home.stylists} />,
  document.getElementById('stylists-section'),
);
